import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchVersion } from '../api/fetchVersion';

type VersionResponse = { version: string };

type UseVersionParams = {
  domain: string;
};

export function useAutoUpdater({ domain }: UseVersionParams) {
  const storedVersion = localStorage.getItem('groseVersion') || 'unknown';

  const queryInfo = useQuery<VersionResponse>({
    queryKey: ['version'],
    queryFn: () => fetchVersion(domain),
    refetchInterval: 60000, // 60 s
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (queryInfo.isSuccess && queryInfo.data) {
      const fetchedVersion = queryInfo.data.version;

      if (fetchedVersion !== storedVersion) {
        // eslint-disable-next-line no-console
        console.log(`Grose - detekována verze ${fetchedVersion}, provádím reload...`);
        localStorage.setItem('groseVersion', fetchedVersion);
        window.location.reload();
      }
    }
  }, [queryInfo.isSuccess, queryInfo.data, domain, storedVersion]);

  return queryInfo;
}
