import g from '../../../assets/g.svg';

function Loader() {
  return (
    <div className="flex items-center justify-center pt-[25vh]">
      <img src={g} alt="" className="size-32 animate-pulse" />
    </div>
  );
}

export default Loader;
