import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from '../components/ui/Loader/Loader';
import { UserContext } from '../contexts/UserContext';

function AdminGuard() {
  const { user, isLoading } = useContext(UserContext);

  if (isLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (user?.role !== 'admin') {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

export default AdminGuard;
